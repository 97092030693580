//
//import Swiper from 'swiper';
//console.log('test')
//window.Swiper = Swiper


console.log("main entrypoint")
// find all a elements, if the href contains "app." then make sure there is a "target=_blank"
document.querySelectorAll('a').forEach(function (a) {
  if (a.href.includes('app.') || a.href.includes('pvolvefranchise.com')) {
    a.target = '_blank'
  }
})